<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 text-center">
        <div class="text-center mt-5">
          <b-img :src="synpitarnLogo" class="mx-auto" style="max-width: 200px" alt="Mascot"></b-img>
        </div>
        <h5 class="my-4">{{ $t('appointment_success_label') }}</h5>
        <h6>
          {{ $t('appointment_success_content') }}
        </h6>
        <router-link to="/home">
          <b-button pill variant="primary px-4 my-4">
            {{ $t('go_to_home') }}
          </b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      synpitarnLogo: require('@/assets/images/sypitarn_mascot.png'),
    }
  },
}
</script>

<style></style>
